import React, { useState } from 'react';
import '../components/css/Footer.css'; // Import the CSS file
import logo from '../components/img/logo-white.svg'
import { Link, useLocation } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { Modal, Button } from 'react-bootstrap';

import Facebook from '../components/img/icon/facebook.svg'
import Insta from '../components/img/icon/insta.svg'
import LinkedIn from '../components/img/icon/linkedIn.svg'
import Twitter from '../components/img/icon/twitter.svg'
import Phone from '../components/img/icon/phone.svg'
import Email from '../components/img/icon/email.svg'
import { useNavigate } from 'react-router-dom';
import config from '../config';
import toast, { Toaster } from "react-hot-toast";

const Footer = () => {
  const navigate = useNavigate();

  const location = useLocation();
  //   const handlePageChane=(e,path)=>{
  // e.preventDefault()
  // navigate(path);

  //   }
  const handlePageChane = (e, path) => {
    e.preventDefault();
    window.location.href = path; // This forces a full page reload to the specified path
  };

  const handleNavigateAndScroll = () => {
    setModalOpen(true)
   // navigate(`${config.baseUrl}join-membership`, { state: { scrollTo: true } });
  };

  const handleScrollToSection = () => {
    const scrollPercentage = 1.9; // Adjust the scroll percentage here
    const scrollY = window.innerHeight * scrollPercentage; // Calculate the Y position to scroll to
    window.scrollTo({
      top: scrollY,
      behavior: 'smooth' // Smooth scrolling
    });
  };

  const [email, setEmail] = useState('')
  const handleChange = (e) => {
    setEmail(e.target.value)
  }
  const handleSubmit = async (e) => {
    e.preventDefault()


    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      toast.error("Please enter a valid email address.");
      return;
    }
    try {

      // check valid email here
      fetch(`${config.apiUrl}subscribe`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      })
        .then(response => response.json())
        .then(data => {
          if (data.status) {
            toast.success(data.message);

          } else {
            toast.error(data.message ? data.message : data.error ? data.error : "Something went wrong. Please try again.")
          }
        })
        .catch((error) => {
          console.error('Error:', error);
          toast.error(error.message ? error.message : error)
          // Handle error (e.g., display error message)
        });
    } catch (error) {
      console.log(error)
    }
  }

    const [modalOpen, setModalOpen] = useState(false)
  
    const handleOtpChange = (e) => {
      setEmail(e.target.value);
    };
  return (
    <footer className='light-bg-Img  mx-0 '>
      <Toaster />
      <Container>
        <Row>
          <Col lg={3} md={3}>
            <div>
              <div className='footer-logo'>
              <img src={logo} className='logo ' />
              </div>
              <h3 className='heading4 '>
                Your money deserves more <br/>Join the movement
              </h3>
              {/* <p className='heading4 mb-1'>Join the movement</p> */}
              <button className='view mt-0' type='button' style={{ fontWeight: "500" }} onClick={handleNavigateAndScroll}>Be a Member</button>
            </div>
          </Col>
          <Col md={9} lg={6}>
            <div className='d-md-flex justify-content-md-around'>
              <div className='footer-list'>
                <h3 className='heading4 hide-mobile'>
                  Quick links
                </h3>
                <ul>
                  {/* <li><Link to={`${config.baseUrl}`}>Home</Link></li>
                  <li><Link to={`${config.baseUrl}stockview`}>Stocks Tracker</Link></li> */}
                  <li><Link to={`${config.baseUrl}about`}>About us</Link></li>
                  <li><Link to={`${config.baseUrl}contact`}>Contact us</Link></li>
                </ul>
              </div>
              <div className='footer-list'>
                <h3 className='heading4 hide-mobile'>
                  Others
                </h3>
                {/* <ul>
                  <li><Link to="/tearms-and-conditions">Terms and conditions</Link></li>
                  <li><Link to="/privacy-policy">Privacy policy</Link></li>
                  <li><Link to="/disclaimer-disclosures">Disclaimer Disclosures</Link></li>
                </ul> */}
                <ul>
                  <li onClick={e => handlePageChane(e, `${config.baseUrl}terms-and-conditions`)}><Link>Terms and conditions</Link></li>
                  <li onClick={e => handlePageChane(e, `${config.baseUrl}privacy-policy`)}><Link>Privacy policy</Link></li>
                  <li onClick={e => handlePageChane(e, `${config.baseUrl}disclaimer-disclosures`)}><Link>Disclaimer & Disclosures</Link></li>
                </ul>
              </div>
              <div className='footer-list hide-mobile'>
                <h3 className='heading4'>
                  Contact Us
                </h3>
                <ul>
                  <li><Link to=""><img src={Phone} className='me-2' /> +91- 7340283616</Link></li>
                  <li><Link to=""><img src={Email} className='me-2' />info@readriches.com</Link></li>
                </ul>
              </div>
            </div>
            <div className='mobile-social-icons footer-list'>
              <ul>
                <li><Link to="https://www.instagram.com/readriches?igsh=MXY3ZnBmOWRjMWMwZA==" target="_blank" rel="noopener noreferrer"><img src={Insta} className='' /></Link></li>
                <li><Link to="https://www.linkedin.com/company/read-riches/" target="_blank" rel="noopener noreferrer"><img src={LinkedIn} className='' /></Link></li>
                <li><Link to="https://x.com/ReadRiches?t=RwnqDBwEFI5s4Twnx1KSMw&s=09" target="_blank" rel="noopener noreferrer"><img src={Twitter} className='' /></Link></li>
              </ul>
            </div>
          </Col>
          <Col lg={3} md={12} className='d-flex justify-content-lg-end  justify-content-center'>
            <div className='mt-3'>
              <h3 className='heading4 subscribe'>
                Subscribe For Latest Updates
              </h3>
              <div className='footer-update'>
                <input type='text' placeholder='E-mail' onChange={e => { handleChange(e) }} />
                <button type='button' onClick={e=>{handleSubmit(e)}}>Subscribe</button>
              </div>
            </div>
          </Col>
        </Row>
        <div className='footer-list hide-mobile mt-md-4'>
          <ul className='d-flex justify-content-center mobile-social-icons'>
          <li><Link to="https://www.instagram.com/readriches?igsh=MXY3ZnBmOWRjMWMwZA==" target="_blank" rel="noopener noreferrer"><img src={Insta} className='' /></Link></li>
                <li><Link to="https://www.linkedin.com/company/read-riches/" target="_blank" rel="noopener noreferrer"><img src={LinkedIn} className='' /></Link></li>
                <li><Link to="https://x.com/ReadRiches?t=RwnqDBwEFI5s4Twnx1KSMw&s=09" target="_blank" rel="noopener noreferrer"><img src={Twitter} className='' /></Link></li>
          </ul>
        </div>
        <div className='copy-right mt-3'>
          <p className='w-75 m-auto'>
          <b>Disclaimer</b>: This website is not registered with SEBI. Before making any investment decisions, please consult a SEBI-registered advisor. The information provided here is for educational purposes only and should not be considered financial advice. 
          </p>
          <p className='mt-2'>
            © 2024ReadRiches|All Rights Reserved|
          </p>
        </div>
      </Container>
      <Modal show={modalOpen} onHide={() => setModalOpen(false)} centered>
        <Modal.Header closeButton style={{ backgroundColor: '#1d666d', color: '#fff' }}>
          <Modal.Title style={{ color: '#fff' }}>Subscribe for early access</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            type="text"
            value={email}
            onChange={handleOtpChange}
            placeholder="Enter your email."
            className="form-control"
          />
         
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" className="view mt-0"  onClick={handleSubmit}>Submit</Button>
        </Modal.Footer>
      </Modal>
    </footer>

    
  );
}

export default Footer;
