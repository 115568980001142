import React, { useState, useRef,useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import AboutImg from '../../components/img/aboutImg.png'
import VisionIMg from '../../components/img/vision.png'
import VisionMob from '../../components/img/visionMob.png'
import Man3 from '../../components/img/man3.png'
import Man2 from '../../components/img/man2.png'
import Man1 from '../../components/img/man1.png'
import { Link } from 'react-router-dom'
import Header from '../header';
import Footer from '../Footer'
import Slider from "react-slick";
import Cube from "../../components/img/icon/cube.svg"
import Users from "../../components/img/users.png"
import Stars from "../../components/img/fiveStar.svg"
import { useNavigate } from 'react-router-dom';
import toast, { Toaster } from "react-hot-toast";
import { Modal, Button } from 'react-bootstrap';
import '../css/About.css'
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'; // Importing icons for custom arrows
import config from '../../config'

function AboutUs() {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
    const [read, setRead] = useState();
    const handleRead = () => {
        setRead(!read)
    }
    const sliderRef = useRef(null);
    const sliderRef2 = useRef(null);

    const next = () => {
        sliderRef.current.slickNext();
    };
    const previous = () => {
        sliderRef.current.slickPrev();
    };
    const next2 = () => {
        sliderRef2.current.slickNext();
    };
    const previous2 = () => {
        sliderRef2.current.slickPrev();
    };
    const settings = {
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "50px",
        slidesToShow: 3,
        slidesToScroll: 1, // Scroll 1 slide at a time
        speed: 1000,
        dots: false,
        initialSlide: 0,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
        ]
    };
    const settings2 = {
        dots: false,
        infinite: true,
        speed: 2000,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        arrows: false,
        autoplay: false,
        autoplaySpeed: 1500,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
           
        ]
    };
    const CustomLeftArrow = ({ onClick }) => {
        return (
            <button onClick={onClick} className="custom-left-arrow">
                <FiChevronLeft size={30} />
            </button>
        );
    };

    // Custom Next Button
    const CustomRightArrow = ({ onClick }) => {
        return (
            <button onClick={onClick} className="custom-right-arrow">
                <FiChevronRight size={30} />
            </button>
        );
    };

    const [expandedDetails, setExpandedDetails] = useState(false); // For details section
    const textForAbout = `Read Riches is a curated investment research platform for investors, who want to do their own research without searching for half-and-half information all over the internet. It is founded with a vision to minimize the time and effort financial research takes by providing a one-stop solution for all your research needs.
  
    What sets us apart is the depth and quality of the research provided to you.
      
    The platform gives a bird’s-eye view of the company’s overall performance with all the smaller details that make a difference in the market.
    
    The neatly categorized bulk information under various sections makes it simple to locate and follow while providing substantive explanations wherever necessary.`;
    const truncateText = (text, wordLimit) => {
        const words = text.split(" ");
        return words.length > wordLimit ? words.slice(0, wordLimit).join(" ") + "..." : text;
    };
    const handleNavigateAndScroll = () => {
        setModalOpen(true)
       // navigate(`${config.baseUrl}join-membership`, { state: { scrollTo: true } });
      };

      const [email, setEmail] = useState('')
      const handleChange = (e) => {
        setEmail(e.target.value)
      }
      const handleSubmit = async (e) => {
        e.preventDefault()
    
    
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
          toast.error("Please enter a valid email address.");
          return;
        }
        try {
    
          // check valid email here
          fetch(`${config.apiUrl}subscribe`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email }),
          })
            .then(response => response.json())
            .then(data => {
              if (data.status) {
                toast.success(data.message);
    
              } else {
                toast.error(data.message ? data.message : data.error ? data.error : "Something went wrong. Please try again.")
              }
            })
            .catch((error) => {
              console.error('Error:', error);
              toast.error(error.message ? error.message : error)
              // Handle error (e.g., display error message)
            });
        } catch (error) {
          console.log(error)
        }
      }
    
        const [modalOpen, setModalOpen] = useState(false)
    return (
        <>
            <div className='light-footer about-page'>
                <Header />
      <Toaster />

                <section className='about_us '>
                    <Container>
                        <Row>
                            <Col md={12}>
                                <div className='position-relative'>
                                    <div className=' about-read'>
                                        <Row className='d-flex align-items-start'>
                                            <Col md={2} className='pe-0'><h3 className='ms-4'>Read</h3></Col>
                                            <Col md={8} className='position-relative p-md-0 p-3'> <img src={AboutImg} alt='about' />
                                            </Col>
                                            <Col md={2} className='ps-0'><h3 className='me-5'>Riches</h3></Col>
                                        </Row>
                                    </div>
                                    <div className='why-us-bg'>
                                    <div className='why-us'>
                                   
                                        <h2>What is <b>Read Riches? </b></h2>
                                        {/* {expandedDetails ? <p>{textForAbout}</p> : <p>{truncateText(textForAbout, 51)}</p>} */}
                                        {!read ?
                                            <p>
                                                Read Riches is a curated investment research platform for investors, who want to do their own research without searching for half-and-half information all over the internet. It is founded with a vision to minimise the time and effort financial research takes by providing a one-stop solution for all your research needs...
                                            </p>
                                            :
                                            <>
                                            {/* <p>
                                                Read Riches is a curated investment research platform for investors, who want to do their own research without searching for half-and-half information all over the internet. It is founded with a vision to minimise the time and effort financial research takes by providing a one-stop solution for all your research needs.
                                                </p>
                                                <p>What sets us apart is the depth and quality of the research provided to you. The platform gives a bird’s-eye view of the company’s overall performance with all the smaller details that make a difference in the market.
                                                </p>
                                                <p>
                                                The neatly categorized bulk information under various sections makes it simple to locate and follow while providing substantive explanations wherever necessary.
                                            </p> */}

                                                    <p style={{ marginBottom: '1em' }}>
                                                        Read Riches is a curated investment research platform for investors, who want to do their own research without searching for half-and-half information all over the internet. It is founded with a vision to minimise the time and effort financial research takes by providing a one-stop solution for all your research needs.
                                                    </p>
                                                    <p style={{ marginBottom: '1em' }}>
                                                        What sets us apart is the depth and quality of the research provided to you. The platform gives a bird’s-eye view of the company’s overall performance with all the smaller details that make a difference in the market.
                                                    </p>
                                                    <p style={{ marginBottom: '1em' }}>
                                                        The neatly categorized bulk information under various sections makes it simple to locate and follow while providing substantive explanations wherever necessary.
                                                    </p>

                                                </>
                                            }
                                            <Link to="" onClick={handleRead}>{!read?"Read More":"Read Less"}</Link>
                                       
                                        {/* {textForAbout.split(" ").length > 51 && (
                                             <Link to="" onClick={() => setExpandedDetails(!expandedDetails)}>{expandedDetails ? "Show Less" : "Read more"}</Link>
                                        )} */}
                                    </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className='section-vision  '>
                    <div className='py-md-4 mobile-view-show'>
                        <img src={VisionMob} className='man-img' />
                    </div>
                    <div className='vision-box py-5' style={{ backgroundColor: "#1d666d" }}>
                        <Container>
                            <Row>
                                <Col md={5}>
                                    <div className='py-md-4 hide-mobile'>
                                        <img src={VisionIMg} style={{ withd: "100%", height: "100%" }} />
                                    </div>
                                </Col>
                                <Col md={7}>
                                    <div className='py-md-5 vision ps-md-5'>
                                        <h2 className='home-heading text-white'>Our Vision</h2>
                                        <p>Read Riches envisions to simplify and speed up the process of financial research which takes enormous time and energy due to the availability of fractional knowledge on multiple platforms. This makes research a mountainous task which it shouldn’t be.</p>
                                        <p>So, we decided to take a deep plunge into the world of research and integrate all the important research parameters into a single platform. This offers a quick one-stop solution for whatever kind of company numbers you’re looking for. It is backed by the narrative behind the numbers which takes into account the qualitative aspects that further eases up the process and understanding.</p>
                                        <p>The vision is to build an exclusive community of finance enthusiasts who value connections and collaborate for the better. This space should be enriched by the experiences of our members, frequent community-building events and a space for dialogue about finance-related topics. </p>
                                        <h3>Come, Be a Part of our Vision!</h3>
                                        <button type='button' className='view' onClick={handleNavigateAndScroll}>Join us </button>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>

                </section>
                <section className='pt-md-5'>
                    <Container>
                        <Row>
                            <div className='text-center'>
                                <h2 className='home-heading'>Why Choose Read Riches</h2>
                                <p  className='center-para-heading'>In the fast-paced world of business and investment, where information isscattered and shallow. Read Riches stands out as a source of clear and thorough insights. Here's why Read Riches is your ultimate companion for navigating the intricacies of the market:</p>
                            </div>
                        </Row>
                    </Container>
                    <div className="slider-container about-slider1">
                        <Slider ref={sliderRef} {...settings}>
                            <div className='about-slider-item'>
                                <div className='slide-item'>
                                    <div className='icon'>
                                        <img src={Cube} />
                                    </div>
                                    <h3>1. One-Stop Company Analysis</h3>
                                    <p>Save yourself time and energy by accessing all important information in one centralised location, saving yourself hours of sifting through scattered information on numerous platforms. Our platform simplifies your search for wisdom. With all essential analyses in one place, we make it easier than ever to access the insights you need, when you need them.</p>
                                </div>
                            </div>
                            <div className='about-slider-item'>
                                <div className='slide-item'>
                                    <div className='icon'>
                                        <img src={Cube} />
                                    </div>
                                    <h3>2. Narrating Numbers</h3>
                                    <p>Read Riches delves deeper than basic data, narrating the story behind the numbers. It provides insights into business performance that extend beyond numerical values by emphasizing on qualitative factors, offering a comprehensive view.
                                        By integrating qualitative with quantitative analysis, we present a multidimensional view of businesses, ensuring you grasp the full scenario—numbers and narratives alike.
                                    </p>
                                </div>
                            </div>
                            <div className='about-slider-item'>
                                <div className='slide-item'>
                                    <div className='icon'>
                                        <img src={Cube} />
                                    </div>
                                    <h3>3. 360° Analysis</h3>
                                    <p>We evaluate companies from every conceivable angle, ensuring no stone is left unturned, from introducing word cloud analysis to cover con-calls and Management discussions to ROE breakdown through Dupont Analysis.</p>
                                    <p>We cater to the fundamental investors. Our commitment to depth means you will receive analyses backed by thorough research, offering insights into market trends and company fundamentals.</p>
                                </div>
                            </div>
                            <div className='about-slider-item'>
                                <div className='slide-item'>
                                    <div className='icon'>
                                        <img src={Cube} />
                                    </div>
                                    <h3>1. One-Stop Company Analysis</h3>
                                    <p>Save yourself time and energy by accessing all important information in one centralised location, saving yourself hours of sifting through scattered information on numerous platforms. Our platform simplifies your search for wisdom. With all essential analyses in one place, we make it easier than ever to access the insights you need, when you need them.</p>
                                </div>
                            </div>
                            <div className='about-slider-item'>
                                <div className='slide-item'>
                                    <div className='icon'>
                                        <img src={Cube} />
                                    </div>
                                    <h3>2. Narrating Numbers</h3>
                                    <p>Read Riches delves deeper than basic data, narrating the story behind the numbers. It provides insights into business performance that extend beyond numerical values by emphasizing on qualitative factors, offering a comprehensive view.
                                        By integrating qualitative with quantitative analysis, we present a multidimensional view of businesses, ensuring you grasp the full scenario—numbers and narratives alike.
                                    </p>
                                </div>
                            </div>
                            <div className='about-slider-item'>
                                <div className='slide-item'>
                                    <div className='icon'>
                                        <img src={Cube} />
                                    </div>
                                    <h3>3. 360° Analysis</h3>
                                    <p>We evaluate companies from every conceivable angle, ensuring no stone is left unturned, from introducing word cloud analysis to cover con-calls and Management discussions to ROE breakdown through Dupont Analysis.</p>
                                    <p>We cater to the fundamental investors. Our commitment to depth means you will receive analyses backed by thorough research, offering insights into market trends and company fundamentals.</p>
                                </div>
                            </div>
                        </Slider>
                        <div className="d-flex align-items-center justify-content-center slider-arrows">
                            <CustomLeftArrow onClick={previous} />
                            <CustomRightArrow onClick={next} />
                        </div>
                    </div>
                </section>
                <section>
                <Container>
                        <div className='user-sec px-3'>
                            <div className='user-icon'>
                                <img src={Users} alt='users' />
                                <span>Our community</span>
                            </div>
                            <h3 style={{ color: "#1D666D", textAlign: "center" }}>
                                Dive into Read Riches’ vibrant <b>WhatsApp community</b>, where financial enthusiasts and investors come alive, exchanging cutting-edge insights and dissecting market trends in real-time—a hub of collaboration and excitement !
                            </h3>
                        </div>

                    </Container>
                    <Row className='slider2 '>
                        <Col lg={1} md={0}></Col>
                        <Col lg={3} md={4} className="what-p px-md-4 py-md-2">
                            <div className='py-md-3 mt-0 mt-5'>
                                <h2 className='home-heading pt-md-4'>What people say <b>About us</b></h2>
                                <p style={{ fontSize: "16px", color: "#3A3A3C" }}>Our community doesn’t talk just about numbers, they talk about experience. Hear what our members have to say about Read Riches</p>
                            </div>
                            <div className=' hide-mobile'>
                                <div className='d-flex justify-content-start m-0 slider-arrows'>
                                    <CustomLeftArrow onClick={previous2} />
                                    <CustomRightArrow onClick={next2} />
                                </div>
                            </div>
                        </Col>
                        <Col md={8}>
                            <div className="slider-container me-4">
                                <Slider ref={sliderRef2} {...settings2}>
                                    <div className='rate-box'>
                                        <img src={Stars} />
                                        <p>“The quick performance charts in community are very useful. They provide clear, concise data at a glance. I’m excited for the website, anticipating deeper insights into market trends.’’ </p>
                                        <h3 className='heading4 mb-0' style={{ fontSize: "20px" }}>Karan Galhotra</h3>
                                        {/* <span style={{ fontSize: "13px", color: "#1E6E76" }}>Auditor at delloit</span> */}
                                    </div>
                                    <div className='rate-box'>
                                        <img src={Stars} />
                                        <p>“Read Riches has very innovative approach to financial data. From live discussions to tools like word cloud analyses, every interaction adds value. I'm excited about the website to see more insights’’ </p>
                                        <h3 className='heading4 mb-0' style={{ fontSize: "20px" }}>-	Sakshi Mehta</h3>
                                        {/* <span style={{ fontSize: "13px", color: "#1E6E76" }}>Auditor at delloit</span> */}
                                    </div>
                                    <div className='rate-box'>
                                        <img src={Stars} />
                                        <p>“Community discussions and resources shared have improved my strategies. I'm waiting for website’s launch, expecting to offer real-time data that can help me make faster, more informed decisions’’ </p>
                                        <h3 className='heading4 mb-0' style={{ fontSize: "20px" }}>-	Rachit Kumar</h3>
                                        {/* <span style={{ fontSize: "13px", color: "#1E6E76" }}>Auditor at delloit</span> */}
                                    </div>
                                    <div className='rate-box'>
                                        <img src={Stars} />
                                        <p>“Joining Read Riches community has given me fresh insights into trends and corporate movements. The members are highly skillful and experienced.  Eagerly awaiting the website for even more advanced financial analysis tools’’ </p>
                                        <h3 className='heading4 mb-0' style={{ fontSize: "20px" }}>-	Pranjal Agarwal</h3>
                                        {/* <span style={{ fontSize: "13px", color: "#1E6E76" }}>Auditor at delloit</span> */}
                                    </div>
                                </Slider>
                            </div>
                        </Col>

                    </Row>
                </section>
                <section className='py-5 mb-0' style={{ backgroundColor: "#1d666d" }}>
                    <Container>
                        <Row>
                            <Col md={3} className="order-md-1 order-2"> {/* Change order for smaller screens */}
                                <div className='people-card'>
                                    <div className='card-img'>
                                        <img src={Man3} />
                                    </div>
                                    <div className='p-3'>
                                        <h3>Rudransh Singhal</h3>
                                        <h4>Co-founder</h4>
                                        <p>A strategic visionary and finance enthusiast committed to transforming investment analysis with innovative solutions </p>
                                    </div>
                                </div>
                            </Col>
                            <Col md={3} className="order-md-2 order-3"> {/* Change order for smaller screens */}
                                <div className='people-card'>
                                    <div className='card-img'>
                                        <img src={Man2} />
                                    </div>
                                    <div className='p-3'>
                                        <h3>Pranay Agarwal</h3>
                                        <h4>Co-founder</h4>
                                        <p>Excels in crafting strategic marketing and growth initiatives driving Read Riches forward with innovative and pragmatic solutions </p>
                                    </div>
                                </div>
                            </Col>
                            <Col md={3} className="order-md-3 order-4"> {/* Change order for smaller screens */}
                                <div className='people-card'>
                                    <div className='card-img'>
                                        <img src={Man1} />
                                    </div>
                                    <div className='p-3'>
                                        <h3>Sanchi Sikka</h3>
                                        <h4>Design Head</h4>
                                        <p>Specialize in creating versatile, intuitive user interfaces, significantly improving user experience and how users interact with platform </p>
                                    </div>
                                </div>
                            </Col>
                            <Col md={3} className="order-md-last order-1"> {/* Change order to first on small screens */}
                                <div className='p-3'>
                                    <h2 className='home-heading' style={{ color: "#E8F0F0" }}>Meet the Architects of Read Riches</h2>
                                    <p style={{ color: "#E8F0F0" }}>
                                        Behind Read Riches lies a team of visionaries dedicated to transforming how financial wisdom is perceived and pursued.
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <Footer />
            </div>
            <Modal show={modalOpen} onHide={() => setModalOpen(false)} centered>
        <Modal.Header style={{ backgroundColor: '#1d666d', color: '#fff' }} closeButton>
          <Modal.Title style={{ color: '#fff' }}>Subscribe for early access</Modal.Title> 
        </Modal.Header>
        <Modal.Body>
          <input
            type="text"
            value={email}
            onChange={handleChange}
            placeholder="Enter your email."
            className="form-control"
          />
         
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" className='view mt-0' onClick={handleSubmit}>Submit</Button>
        </Modal.Footer>
      </Modal>
        </>
    )
}

export default AboutUs